import React, { ReactElement } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import { StyledButton } from '../components/common/Buttons';
import { Image } from '../utils/image';

const NewPassword = (): ReactElement => (
    <Layout>
        <Container>
            <Row
                noGutters
                style={{
                    backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
                    padding: `3rem`,
                }}
            >
                <Col
                    lg="6"
                    md="12"
                    style={{
                        paddingLeft: `2rem`,
                        paddingRight: `2rem`,
                        backgroundColor: `#ffffff`,
                    }}
                >
                    <div>
                        <Image src="/images/new-password@3x.png" />
                        <p style={{ fontSize: `0.8rem`, color: `#535b6c` }}>
                            WorkForce / Employee Identity management - Useful
                            Unified view to view all employees and assign them
                            into different
                        </p>
                    </div>
                </Col>
                <Col
                    lg="6"
                    md="12"
                    style={{
                        paddingLeft: `2rem`,
                        paddingRight: `2rem`,
                        backgroundColor: `#f9f9f9`,
                    }}
                >
                    <div>
                        <div
                            style={{
                                textAlign: `center`,
                                color: `#2382b6`,
                                marginTop: `2rem`,
                                marginBottom: `4rem`,
                            }}
                        >
                            <h5>Create New Password</h5>
                        </div>
                        <Form>
                            <Form.Group
                                style={{ paddingBottom: `1rem` }}
                                controlId="formBasicNewPassword"
                            >
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    style={{ borderRadius: `1rem` }}
                                    type="email"
                                    placeholder="New Password"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    style={{ borderRadius: `1rem` }}
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </Form.Group>
                            <div
                                style={{
                                    textAlign: `center`,
                                    paddingTop: `2rem`,
                                }}
                            >
                                <StyledButton type="submit">
                                    Create
                                </StyledButton>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
);

export default NewPassword;
